import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Bank Accounts --------------------
    addBankAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/bank-accounts', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBankAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/bank-accounts/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBankAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/bank-accounts/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBankAccounts(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/bank-accounts', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // -------Bank Accounts --------------
  },
}
