<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >

    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BriefcaseIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Banka Hesapları') }}</span>
      </template>

      <payment-settings-bank-accounts />
    </b-tab>
    <!--/ Sabit Teklif Maddeleri -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import PaymentSettingsBankAccounts from './PaymentSettingsBankAccounts.vue'

export default {
  components: {
    BTabs,
    BTab,
    PaymentSettingsBankAccounts,
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {

  },
}
</script>
